import React, { useContext, useEffect, useState } from 'react'
import './ContactUs.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { mapData, handleRedirectInternal } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import AuthContext from '../../../Product/context/auth/authContext'
import UserContext from '../../../Product/context/user/userContext'
import Layout from '../Layout'
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api'
import { fromAddress, setKey } from 'react-geocode'
import schedulePickupContext from '../../../Product/context/plugin/schedulePickup/schedulePickupContext'

function ContactUs() {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const { getAllTimings, all_timings } = useContext(schedulePickupContext)
    const { send_contactus, responseStatus } = userContext
    const { user, loadUser } = authContext
    const history = useHistory()
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const contactAddress = global?.storeDetails?.address
    const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(true)
    const [allLocations, setAllLocations] = useState({})

    const location = {
        lat: 39.19917755829463,
        lng: -84.45295064810153,
        redirectionURL: 'https://maps.app.goo.gl/FMMh2dQ5JsMRryk58',
        phone: global.storeSeller.phone,
    }

    const style = {
        height: '750px',
        width: '100%',
        maxWidth: '100%',
        border: '10px solid #fff',
        margin: '10px 0 0 auto',
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyANJvSnxieaGRhq_Ndt1Fe4MKDkDYE-SOM',
    })

    setKey('AIzaSyANJvSnxieaGRhq_Ndt1Fe4MKDkDYE-SOM')

    const [map, setMap] = useState(null)

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const handleLocation = (address) => {
        window.open(address, '_blank')
    }

    const validationArray = Yup.object({
        name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Special characters not allowed')
            .max(15, 'Maximum 15 characters')
            .required('Required'),

        from_email: Yup.string().email('Invalid email format').required('Required'),
        phone_number: Yup.string()
            .trim()
            // .matches(/^\+/, 'Please enter country code')
            .matches(/^[+]?[-]?[^a-z][0-9]+$/, 'Invalid Number!')
            .min(10, 'Invalid Number!')
            .max(16, 'Maximum 14 characters')
            .required('Required'),
        subject: Yup.string().required('Required'),
        messages: Yup.string().trim().required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            last_name: '',
            from_email: '',
            phone_number: global?.storeConfigration?.default_phone_code?.value
                ? global?.storeConfigration?.default_phone_code?.value
                : '',
            subject: '',
            messages: '',
            is_send_email: 1,
            phone_number_new: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            send_contactus(values)
        },
    })

    function formatWorkingHours(schedule) {
        const daysWithSameHours = {}

        // Group days with same working hours
        schedule.forEach((day) => {
            if (day.Status === 'Open') {
                const timeKey = `${day.Day_Times[0].start_time}-${day.Day_Times[0].end_time}`
                if (!daysWithSameHours[timeKey]) {
                    daysWithSameHours[timeKey] = []
                }
                daysWithSameHours[timeKey].push(day.Day)
            }
        })

        const workingHoursParts = []
        const closedDays = []

        // Process each time group
        Object.entries(daysWithSameHours).forEach(([timeKey, days]) => {
            const [startTime, endTime] = timeKey.split('-')
            const formattedStartTime = startTime.replace(' ', '').toLowerCase()
            const formattedEndTime = endTime.replace(' ', '').toLowerCase()

            // Check for consecutive days
            const sortedDays = [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ].filter((day) => days.includes(day))

            const consecutiveGroups = []
            let currentGroup = [sortedDays[0]]

            for (let i = 1; i < sortedDays.length; i++) {
                const prevDayIndex = [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                ].indexOf(sortedDays[i - 1])
                const currentDayIndex = [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                ].indexOf(sortedDays[i])

                if (currentDayIndex === prevDayIndex + 1) {
                    currentGroup.push(sortedDays[i])
                } else {
                    consecutiveGroups.push(currentGroup)
                    currentGroup = [sortedDays[i]]
                }
            }
            consecutiveGroups.push(currentGroup)

            // Format consecutive and non-consecutive day groups
            consecutiveGroups.forEach((group) => {
                if (group.length > 1) {
                    workingHoursParts.push(
                        `(${formattedStartTime} - ${formattedEndTime}) ${group[0]} - ${
                            group[group.length - 1]
                        }`,
                    )
                } else {
                    workingHoursParts.push(
                        `(${formattedStartTime} - ${formattedEndTime}) ${group[0]}`,
                    )
                }
            })
        })

        // Find closed days
        const allDays = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ]
        schedule.forEach((day) => {
            if (day.Status !== 'Open') {
                closedDays.push(day.Day)
            }
        })
        // const remainingClosedDays = allDays.filter(
        //     (day) => !schedule.some((s) => s.Day === day && s.Status === 'Open'),
        // )

        const formattedClosedDays = [...closedDays].join(' & ')

        return (
            <span className="info-text">
                {workingHoursParts.map((val) => (
                    <>
                        {' '}
                        <b>{val}</b>
                        <br />
                    </>
                ))}

                {formattedClosedDays.length > 0
                    ? `CLOSED ${formattedClosedDays.toUpperCase()}`
                    : null}
            </span>
        )
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'send_contactus') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        getAllTimings({
            site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
            random_id: '',
            id: '',
        })
    }, [])

    useEffect(() => {
        if (
            all_timings.all_locations.length > 0 &&
            all_timings.all_locations[0].range_key.length > 0
        ) {
            setAllLocations(all_timings.all_locations[0].range_key[0])
        }
    }, [all_timings.all_locations])

    useEffect(() => {
        if (global?.storeConfigration?.default_phone_code?.value) {
            var phone_code = global?.storeConfigration?.default_phone_code?.value
            if (
                formik.values.phone_number.length < phone_code.length ||
                !formik.values.phone_number.includes(phone_code)
            ) {
                formik.setFieldValue('phone_number', formik.values.phone_number_new)
            } else {
                formik.setFieldValue('phone_number_new', formik.values.phone_number)
            }
        }
    }, [formik.values.phone_number])

    const getCenterForGridMap = (loc) => {
        return { lat: loc.lat, lng: loc.lng, zoom: 8 }
    }

    const contactInfo = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-sm-6 col-12',
            autoFocus: true,
            formik: formik,
        },

        {
            label: 'Email address',
            name: 'from_email',
            type: 'text',
            placeholder: 'Enter your email address',
            class: 'col-sm-6 col-12',
            formik: formik,
        },
        {
            label: 'Phone number',
            name: 'phone_number',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: 'col-sm-6 col-12',
            formik: formik,
        },
        {
            label: 'Subject',
            name: 'subject',
            type: 'text',
            placeholder: 'Enter your subject',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Message',
            name: 'messages',
            type: 'textarea',
            placeholder: 'Enter your message',
            class: 'col-12',
            formik: formik,
        },
    ]
    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        if (user) {
            formik.values.name = user?.first_name + ' ' + user?.last_name || ''
            formik.values.phone_number = user?.phone || ''
            formik.values.from_email = user?.email || ''
            formik.values.subject = 'General Enquiry'
        }
    }, [user])

    return (
        <Layout>
            <div className="contact-main-container">
                <div className="googleMap padtop">
                    <div className="cu-head">Contact us</div>
                    {isLoaded ? (
                        <>
                            {' '}
                            <GoogleMap
                                mapContainerStyle={style}
                                mapView={true}
                                center={{
                                    lat: location.lat,
                                    lng: location.lng,
                                }}
                                zoom={11}
                                onUnmount={onUnmount}
                            >
                                <Marker
                                    position={{
                                        lat: location.lat,
                                        lng: location.lng,
                                    }}
                                    // onClick={() => handleLocation(location.redirectionURL)}
                                />

                                <InfoWindow
                                    position={{ lat: location.lat + 0.002, lng: location.lng }}
                                    options={{
                                        pixelOffset: new window.google.maps.Size(0, -40),
                                        disableAutoPan: true,
                                    }}
                                >
                                    <div className="custom-info-window">
                                        <div className="info-window-row">
                                            <span className="info-icon material-icons">call</span>
                                            <span className="info-text">
                                                {allLocations.business_phone}
                                            </span>
                                        </div>
                                        <div className="info-window-row">
                                            <span className="info-icon material-icons">
                                                location_on
                                            </span>
                                            <span className="info-text">{`${allLocations.business_address1}, ${allLocations.business_city}, ${allLocations.business_state}, ${allLocations.business_zip}`}</span>
                                        </div>
                                        <div className="info-window-row">
                                            <span className="info-icon material-icons">
                                                schedule
                                            </span>

                                            {allLocations?.general?.length > 0
                                                ? formatWorkingHours(allLocations.general)
                                                : ''}
                                        </div>
                                    </div>
                                </InfoWindow>
                            </GoogleMap>
                        </>
                    ) : null}
                </div>

                {/* <div className="padtop"> */}
                <div className="contactform">
                    <div className="cu-head">Send us a note</div>
                    <div className="text-center scontact-us-wrap flex-btnscol">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                {Object.values(mapData(contactInfo))}
                                <div className="col-12">
                                    <PrimaryButton label="Submit" type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/* </div> */}
                {/* <div className="container cu-margin-125">
                    <div className="row d-flex justify-content-center">

                        <div
                            className={`"col-xs-12" 'col-md-4'
                             "padtop"`}
                        >
                            <div className="contactform cfRt">
                                <div className="cu-head">Reach Us</div>
                                {contactAddress && contactAddress != 'null' && (
                                    <div className="media">
                                        <div className="media-left">
                                            <span className="material-icons">location_on</span>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="ru-head">Address</h5>
                                            {contactAddress.split('&').map((val) => (
                                                <span className="ru-con">{val}</span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {contactEmail && (
                                    <div className="media">
                                        <div className="media-left">
                                            <span className="material-icons">mail</span>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="ru-head">Email address</h5>
                                            <a href={`mailto:${contactEmail}`} className="ru-con">
                                                {contactEmail}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {contactPhone && (
                                    <div className="media">
                                        <div className="media-left">
                                            <span className="material-icons">call</span>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="ru-head">Call</h5>
                                            <a href={`tel:${contactPhone}`} className="ru-con">
                                                <span dir="ltr">{contactPhone}</span>
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </Layout>
    )
}

export default ContactUs
